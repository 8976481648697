import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ad9740c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup__container" }
const _hoisted_2 = { class: "popup__title" }
const _hoisted_3 = { class: "popup__button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_MainButton = _resolveComponent("MainButton")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "popup",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args)), ["self"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "popup__close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args)))
          }, [
            _createVNode(_component_CloseIcon)
          ]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(this.translateData.are_you_sure_you_want_to_delete_your_account), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_MainButton, { onClick: _ctx.handleDelete }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.translateData.yes), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_MainButton, { onClick: _ctx.handleCloseModal }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.translateData.no), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}