
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import SettingsSection from '@/components/sections/SettingsSection.vue'

export default defineComponent({
  name: 'Settings',
  components: {
    Main,
    SettingsSection
  }
})
